import React, { useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";

const AddressCompleteComponent = ({ dispatch, type}) => {
  const [address, setAddress] = useState("Address");
  const [errorMessage, setErrorMessage] = useState(null);

  const extractProp = (addressObj, prop) => 
    addressObj[0].address_components.find((component) =>
    component.types.includes(prop)
   )?.long_name

   const extractPropSN = (addressObj, prop) => 
    addressObj[0].address_components.find((component) =>
    component.types.includes(prop)
   )?.short_name

const handleSelectCity = async (place) => {
    const extraInfo = await geocodeByAddress(place.value.description);
    let city = extractProp(extraInfo, "locality")
    if (!city) city = extractProp(extraInfo, "administrative_area_level_3")
    if (!city) city = extractProp(extraInfo, "administrative_area_level_2")
    if (!city) city = extractProp(extraInfo, "administrative_area_level_1")
    if (!city) city = extractProp(extraInfo, "administrative_area_level_4")
    dispatch({City: city})
}

  const handleSelect = async (place) => {
    const extraInfo = await geocodeByAddress(place.value.description);
    console.log(extraInfo)
    const zipCode = extractProp(extraInfo, "postal_code")
    const address = extractProp(extraInfo, "route")
    const city = extractProp(extraInfo, "locality")
    const country = extractProp(extraInfo, "country")
    const number = extractProp(extraInfo, "street_number")
    let state = extractPropSN(extraInfo, "administrative_area_level_2")
    if (!state) state = extractPropSN(extraInfo, "administrative_area_level_1")
    if (!state) state = extractPropSN(extraInfo, "administrative_area_level_3")

    if (zipCode && address && city && country && number) {
      setErrorMessage(null);
      setAddress(place.description);
      dispatch({
        CAP: zipCode,  
        ViaPiazza: address + " " + number,
        Indirizzo: place.value.description,
        City: city,
        Country: country,
        State: state
        })
    } else {
        dispatch({})
        setErrorMessage(
            "Si prega di inserire un indirizzo completo di numero civico."
          );
    }
     
    }

  return (
    <div className="address-picker">
      <GooglePlacesAutocomplete
        apiKey="AIzaSyC9alQXhJaaPYFxbWoIniUsIpnrOoJJf9k"
        lang="en"
        query={{
            key:"AIzaSyC9alQXhJaaPYFxbWoIniUsIpnrOoJJf9k",
            language:"en"
        }}
        minLengthAutocomplete={4}
        onLoadFailed={(error) =>
          console.error("Could not inject Google script", error)
        }
        debounce={300}
        searchOptions={{
          types: ["(regions)"],
        }}
        apiOptions={{
          searchOptions: { 
            types: type === "city" ? ['(cities)'] :["(regions)"] },
          language: "en",
        }}
        language="en"
        // autocompletionRequest={{
        //   componentRestrictions: { country: "it" },
        // }}
        selectProps={{
          address,
          onChange: type === "city" ? handleSelectCity : handleSelect,
          placeholder: "Indirizzo",
          className: "address-input",
        }}
        //  onSelect={handleSelect}
      />
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
    </div>
  );
};

export default AddressCompleteComponent;

import React, { useState, useEffect } from 'react';
import AddressCompleteComponent from './AddressComponent';
import Select from 'react-select'
import nationalities from './nationalities'

import { Button, Col, Form, Row, Spinner } from "react-bootstrap"

import logo from './logoeit.png';
import mfhea from './mfhea.svg';

function App() {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [validation, setValidation] = useState([])
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [PoB, setPoB] = useState()
  const [DoB, setDoB] = useState()
  const [TaxID, setTaxID] = useState() 
  const [nationality, setNationality] = useState()
  const [thanks, setThanks] = useState(false)
  const [terms, setTerms] = useState(false)
  const [address, setAddress] = useState({})
  const [documentType, setDocumentType] = useState()
  const [documentNumber, setDocumentNumber] = useState()

  const setInfo = (data) => {
    setAddress(data)
  }

  const setInfoCity = (data) => { 
    setPoB(data.City)
  }

  const documentTypes = ["Identity Card", "Passport", "Residence Permit"].map(item => ({ value: item, label: item }))

  const exist = (value) => {
    return !(value !== null && value !== undefined && value !== "")
   }

  const submit = async () => {
    const toSend = {
      firstname,
      lastname,
      birthplace: PoB,
      dob: new Date(DoB).getTime(),
      tax_id: TaxID,
      zip: address.CAP,
      address: address.ViaPiazza,
      city: address.City,
      country: address.Country,
      state: address.State,
      nationality: nationality,
      type_of_document: documentType,
      document_number: documentNumber
    }
    const sv = []
    
    setValidation([])
    if (exist(firstname)) 
      sv.push("First name is required")
    if (exist(lastname))
      sv.push("Last name is required")
    if (exist(DoB)) 
      sv.push("Date of Birth is required")
    if (exist(PoB))
      sv.push("Place of Birth is required")
    if (exist(TaxID))
      sv.push("Tax ID is required")
    else if (address.Country === "Italy" && TaxID.length !== 16)
      sv.push("Tax ID must be the Italian Codice Fiscale (16 characters) for Italian residents.")
    if (exist(address.CAP))
      sv.push("CAP / ZIP Code is required. Please check the Address field")
    if (exist(address.Indirizzo))
      sv.push("Address is required")
    if (exist(address.City)) 
      sv.push("City is required")
    if (exist(address.Country))
      sv.push("Country is required")
    if (exist(address.State))
      sv.push("State is required")
    if (exist(documentType))
      sv.push("Document Type is required")
    if (exist(documentNumber))
      sv.push("Document Number is required")
    if (sv.length > 0) { setValidation(sv); return; }
   
    console.log(toSend)

    setIsLoading(true)

    try {
      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get('email');
      const response = await fetch('https://striveschool-be.herokuapp.com/api/crm/student/'+email, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(toSend)
      })
      if (response.ok) {
        setThanks(true)
      }
      else {
        setValidation(["An error occurred. Please try again later"])
      }
    }
    catch(e) {
      console.log(e)
      setValidation(["An error occurred. Please try again later"])
    }
    finally {
      setIsLoading(false)
    }
  }


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    if (thanks)
      window.location.href = "https://buy.stripe.com/bIYdTJfG06pXe0UcMM?prefilled_email=" + email
  }, [thanks])

  useEffect(() => {
    const fetchData = async () => { 
      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get('email');
      if(email === "123123122312312123123")
        return;
      if(email === null){
        setError("Invalid Address")
        return;
      }
      try {
        const response = await fetch('https://striveschool-be.herokuapp.com/api/crm/student/'+ email)
        if (response.status === 400){
          setError(await response.text())
          return
        }
        if (response.status === 404) {
          setError("The email address is not valid. Please contact your laurea@epicode.com")
          return;
        }
        const data = await response.json()
        setFirstname(data.firstname)
        setLastname(data.lastname)
      }
      catch(e) {
        console.log(e)
      }
      
    }
    fetchData()
  }, [])

  if (thanks) { 
    //redirect a stripe
    return <div className="container">
      <header className="header">
        <img src={logo}  alt="logo" height={40} />
        <img src={mfhea}  alt="logo" height={40} />
      </header>
      <div className="form col-md-6 offset-md-3 py-5 mt-5">
        <h2 className="text-center">
          Thank you for submitting the form! 📩 <br /><br />
          <a href="https://buy.stripe.com/bIYdTJfG06pXe0UcMM"> Complete now your seat reservation fee! </a>
        </h2>
      </div>
    </div>
  }

  return (
    <div className="container">
      <header className="header">
        <img src={logo}  alt="logo" height={40} />
        <img src={mfhea}  alt="logo" height={40} />
      </header>
      { error ? <p>
        {error}
      </p>: 
      <div style={{ minHeight: "90vh", display: "flex", alignItems: "center"}}>
      <div className="form col-md-10 offset-md-1">
        <h2 className="text-center mb-4">
        Welcome to our onboarding process. 
        </h2>
        <div className='normal-text mb-3'>Please fill out the form with your personal information. <br /> Once you click "Submit," you will be redirected to the payment page.</div>
      <Row>
      <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
        <Form.Label>First Name</Form.Label>
        <Form.Control type="text" placeholder="First Name" value={firstname} onChange={(e) => setFirstname(e.currentTarget.value) } />
      </Form.Group>
      <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
        <Form.Label>Last Name</Form.Label>
        <Form.Control type="text" placeholder="Last Name" value={lastname} onChange={(e) => setLastname(e.currentTarget.value) } />
      </Form.Group>
      </Row>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Address</Form.Label>
        <AddressCompleteComponent dispatch={setInfo} />
      </Form.Group>
      <Row>
      <Form.Group className="mb-3 col-md-9" controlId="formBasicEmail">
        <Form.Label>Birthplace</Form.Label>
        <AddressCompleteComponent dispatch={setInfoCity} type="city" />
      </Form.Group>
      <Form.Group className="mb-3 col-md-3" controlId="formBasicEmail">
        <Form.Label>Date of Birth</Form.Label>
        <Form.Control placeholder="dd-mm-yyyy"  type="date" value={DoB} onChange={(e) => setDoB(e.currentTarget.value) } />
      </Form.Group>
  
      </Row>
      <Row>
      <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
        <Form.Label>Tax ID (Codice Fiscale)</Form.Label>
        <Form.Control type="text" placeholder="Tax ID" value={TaxID} onChange={(e) => setTaxID(e.currentTarget.value.toUpperCase()) } />
      </Form.Group>
      <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
        <Form.Label>Nationality</Form.Label>
        <Select options={nationalities} placeholder="Nationality" onChange={e => setNationality(e.value)} />
      </Form.Group>
      </Row>
      <Row>
      <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
        <Form.Label>Document Type</Form.Label>
        <Select options={documentTypes} placeholder="Document Type" onChange={e => setDocumentType(e.value)} />
      </Form.Group>
      <Form.Group className="mb-3 col-md-6" controlId="formBasicEmail">
        <Form.Label>Document Number</Form.Label>
        <Form.Control type="text" placeholder="Document Number" value={documentNumber} onChange={(e) => setDocumentNumber(e.currentTarget.value.toUpperCase()) } />
      </Form.Group>
    
      </Row>
        <Row>
        <Col>
      <input  className="me-2" type="checkbox" id="terms" name="terms" value={terms} onChange={e => setTerms(e.target.checked)} />
        <label  htmlFor="terms">I agree to the <a style={{ fontWeight: "bold", color: "rgb(120, 1, 139)"}} href="https://instituteoftechnology.epicode.com/deposito-cauzionale/" target='_blank' >Terms and Conditions</a></label>
        </Col>
        </Row>
       <Row className="my-4"> 
        <Col className='text-center'>
        <Button variant="primary" disabled={!terms} onClick={submit}> 
          Submit
        </Button>
       </Col>
       </Row>

      
        {isLoading && <div className="text-center">
            <Spinner animation="grow" variant="success" />
        </div>
        }

       {validation && validation.length > 0 && <div className="text-danger">
          Cannot submit the form. Please check the following fields:
          <ul>
          {validation.map((item, index) => <li key={index}>{item}</li>)}
          </ul>
        </div>}
        </div>

        </div>
      }
    </div>
  );
}

export default App;
